<template>
     <section class="login-content">
         <div class="container h-100">
            <div class="row align-items-center justify-content-center h-100">
               <div class="col-12">
                  <div class="row align-items-center">
                     <div class="col-lg-6">
                        <h1 class="header-logo">
                           <img :src="logo" class="img-fluid rounded-normal" alt="logo" width="200px"> | <span>intel</span>insurance
                        </h1> 
                        <br>
                        <!--h2 class="mb-2">Ingresar</h2-->
                        <p>Para permanecer conectado por favor ingresa con tu información personal.</p>
                        <form  @submit.prevent="login">
                           <div class="row">
                              <div class="col-lg-12">
                                 <div class="floating-label form-group">
                                    <input class="floating-input form-control" type="email" placeholder=" " v-model="email">
                                    <label>Email</label>
                                 </div>
                              </div>
                              <div class="col-lg-12">
                                 <div class="floating-label form-group">
                                    <input class="floating-input form-control" type="password" placeholder=" " v-model="password">
                                    <label>Contraseña</label>
                                    <div class="invalid-feedback" style="display:block"  v-if="errorCredenciales">Las credenciales son incorrectas, revise la información y vuelva a intentarlo</div>
                                 </div>
                              </div>
                              <div class="col-lg-6">
                                 <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Recordarme</label>
                                 </div>
                              </div>
                              <!--div class="col-lg-6">
                                 <a href="auth-recoverpw.html" class="text-primary float-right">Olvidaste tu contraseña?</a>
                              </div-->
                           </div>
                           <button type="submit" class="btn btn-primary">Ingresar</button>                           
                        </form>
                     </div>                     
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<style   lang="scss">
   .header-logo {
      color: grey;
      font-size: 20px;
      span {
         background: #00d4ba;
         padding: 2px;
         color: white;
         border-radius: 6px;

      }
   }
</style>
<script>
import { mapGetters,mapState  } from 'vuex'
export default {
   data() {
      return {
        email: "",
        password: "",
        errorCredenciales: false
      }
    },
   created() {
      $('body').attr('id', "auth");
   },
    name:'SignIn',
    computed : {
      ...mapState(['wookieMembership']),
      ...mapGetters({
         appName: 'appName',
         logo:'logo',           
         name:'name'}
      )
    },
    methods: {
      login: function() {
         let email = this.email
         let password = this.password

         this.errorCredenciales = false;

         this.$store.dispatch('intelinsuranceApi/login', { email, password })
         .then(() => {
            this.$router.push('/')                      
         })
         .catch(err => {
            //if (err.response.data.error.code == 100) {
            //   this.errorCredenciales = true
            //}
            console.log(err);
            if (err.status == 500) {
               this.errorCredenciales = true
            }
            
         })      
      }
   }
}
</script>